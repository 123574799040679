import { SurveyDesignSample } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSlice';
import { Samples, Assay } from './samples';
import { SurveyDesign } from './survey';
import { CMSHabitatAssayType } from '../cms/habitatAssayType';

export type Taxonomy = {
    tax_class: string | null;
    tax_family: string | null;
    tax_genus: string | null;
    tax_kingdom: string | null;
    tax_order: string | null;
    tax_phylum: string | null;
    tax_species: string | null;
};
// export enum ChegdGroup {
//     Clavarioids = "Clavarioids",
//     Hygrocybes = "Hygrocybes",
//     Entolomas = "Entolomas",
//     Geoglossaceae = "Geoglossaceae",
// }

export enum GriisStatus {
    NOT_FOUND = 'Not found',
    INVASIVE = 'Invasive',
    NOT_INVASIVE = 'Not invasive',
}

export type ConservationStatus = {
    iucnRedListStatus: string;
    chegdCommonName: string;
    // TODO: This should be an enum
    chegdGroup: string;
    onGRIISList: GriisStatus | boolean;
};

export type TaxonomySummary = {
    assayId: string;
    conservationStatus: ConservationStatus;
    target: string;
    sampleCount: number;
    percentTotalSamples: number;
    taxonomy: Taxonomy;
    samples: string[];
    nmSeqId: string;
}[];

export type SampleGroups = {
    key: string;
    name: string;
}[];

export type MarkerDetails = {
    markerId: string;
    markerTarget: {
        key: string;
        name: string;
    };
}[];

export type ProjectDefinitionSample = SurveyDesignSample;

export type ProjectDefinition = {
    projectId: string;
    customerId: string;
    projectName: string;
    projectCode: string;
    projectType: string;
    projectEndDate: string;
    area: string;
    country: string;
    targetOutcome: string;
    habitatAssay: CMSHabitatAssayType[];
    leadName: string;
    leadEmail: string;
    leadRole: string;
    subscriptionType: string;
    subscription: {
        name: string;
        label: string;
    };
    samplingCadence: SamplingCadence | null;
    sampleGroups: string[];
    speciesOfInterest: string[];
    files: ProjectFile[];
    createdAt: string;
    creatorName: string;
    samplesCount: number;
    estimatedSampleCounts: number;
    estimatedSampleCountForTestTypes: {
        type: Pick<CMSHabitatAssayType, 'assayName' | 'habitatAssayKey' | 'habitatName'>;
        count: number;
    }[];
    status: ProgressStatus;
    survey: SurveyDesign | null;
    initialSampleEntries: ProjectDefinitionSample[];
    isChegdProject: boolean;
    griisLookupCountries: string[];
};

export type ProjectFile = {
    name: string;
    awsFileKey: string;
    fileType: string;
    fileCreationDate: string;
};

export type Project = {
    customerId: string;
    projectId: string;
    projectName: string;
    projectStartDate: string;
    latestReportingDate: string;
    orderIds: string[];
    taxonomySummary: TaxonomySummary;
    sampleGroups: SampleGroups;
    markerDetails: MarkerDetails;
    samples: Samples;
    assays: Assay[];
    files: ProjectFile[];
    habitatAssay: CMSHabitatAssayType[];
};

export type HabitatAssay = {
    key: string;
    singleTimepoint: boolean;
    habitat: {
        habitatKey: string;
        habitatName: string;
    };
    assay: {
        assayId: string;
        assayName: string;
    };
    metrics: {
        metricKey: string;
        metricName: string;
    }[];
};

export type ProjectFilters = {
    map: {
        times: {
            firstSampleDate: string;
            lastSampleDate: string;
        } | null;
        areas: string[] | null;
        habitatAssays: HabitatAssay[] | null;
        hlcaChartTypes: string[];
        hlcaSites: string[];
    };
};

export enum ProjectSamplingCadenceType {
    SINGLE_YEAR = 'SINGLE_YEAR',
    MULTI_YEAR = 'MULTI_YEAR',
}

export type SamplingEvent = {
    name: string;
    fromDate: string;
    toDate: string;
};

export type SamplingCadence = {
    type: ProjectSamplingCadenceType;
    events: SamplingEvent[];
    samplingEventsPerYear: number;
};

export type ProjectMeta = {
    projectId: string;
    customerId: string;
    projectName: string;
    projectCode: string;
    leadName: string;
    leadEmail: string;
};

export enum ProgressStatus {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
}

export type ProjectDefinitionCompletionStatus = {
    exists: boolean;
    metadata?: {
        id: string;
        status: ProgressStatus;
        createdBy: string;
        creatorName: string;
        isCreatedByCurrentUser: boolean;
    };
};

export enum BasicProjectType {
    GENERIC = 'GENERIC',
    MULTIYEAR_CONTRACT = 'MULTIYEAR_CONTRACT',
}

export enum SubscriptionType {
    INSIGHTS = 'Insights',
    BASIC = 'Basic',
    SURVEY_WITH_HABITAT_INSIGHTS = 'Survey_HabitatInsights',
}

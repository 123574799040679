import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { NewProjectDefinitionFormState, setFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import { getCurrentProjectDefinitionFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSelector';
import Input from 'src/shared/components/input/Input';
import BasicDatePicker from 'src/shared/components/basic-datepicker/BasicDatePicker';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { ProjectSamplingCadenceType, SamplingEvent } from 'src/shared/types';
import commonStyles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import RadioGroup from 'src/shared/components/radio-group/RadioGroup';
import Select from 'src/shared/components/select/Select';
import times from 'lodash/times';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import styles from './SamplingCadence.module.scss';

const samplingCadenceOptions = [
    { label: 'Single year', value: ProjectSamplingCadenceType.SINGLE_YEAR },
    { label: 'Multi year', value: ProjectSamplingCadenceType.MULTI_YEAR },
];

const numberOfEventOptions = [...times(12, index => ({ label: index + 1, value: String(index + 1) }))];

const SamplingCadence = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentProjectDefinitionFormState);
    const { samplingCadence, samplingEvents, samplingEventsPerYear } = formState;

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        const samplingEventsFragment = samplingEvents.slice(0, samplingEventsPerYear);

        dispatch(
            setFormState({
                ...fragment,
                samplingEvents: samplingEventsFragment,
            })
        );
    };

    return (
        <div className={commonStyles.formView}>
            <div className={commonStyles.formViewTitle}>Sampling cadence</div>

            <div className={commonStyles.formSection}>
                <div className={commonStyles.formInputSection}>
                    <div className={commonStyles.formInputSectionTitle}>Will this be a multi year or single year project?</div>
                    <RadioGroup
                        options={samplingCadenceOptions}
                        onChange={value => onFormChange({ samplingCadence: value as ProjectSamplingCadenceType })}
                        selectedOption={samplingCadence}
                    />

                    {samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR && (
                        <InfoMessage message={<CMSArticle slug='multi-year-project-sampling-events' />} />
                    )}
                </div>

                <div className={commonStyles.formInputSection}>
                    <div className={commonStyles.formInputSectionTitle}>
                        Number of sampling events per year
                        <QuestionButtonHelp type='api' slug='project-definition-sampling-cadence' />
                    </div>

                    <Select
                        className={{ container: commonStyles.selectContainer }}
                        options={numberOfEventOptions}
                        selectedValue={String(samplingEventsPerYear || '')}
                        onChange={value => onFormChange({ samplingEventsPerYear: Number(value) })}
                        placeholder='Select number'
                        width='100%'
                    />
                </div>

                <div className={commonStyles.formInputSection}>
                    {times(samplingEventsPerYear, index => {
                        return <SamplingEventForm key={index} index={index} />;
                    })}
                </div>
            </div>
        </div>
    );
};

const SamplingEventForm = (props: { index: number }) => {
    const { index } = props;

    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentProjectDefinitionFormState);

    const { samplingEvents } = formState;
    const samplingEventData = samplingEvents[index] || {
        name: '',
        fromDate: '',
        toDate: '',
    };

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const onSampleEventChange = (samplingEventFragment: Partial<SamplingEvent>) => {
        const samplingEventsCopy = [...samplingEvents];
        samplingEventsCopy[index] = { ...samplingEventData, ...samplingEventFragment };

        onFormChange({ samplingEvents: samplingEventsCopy });
    };

    return (
        <div className={styles.samplingEvent}>
            <div className={styles.samplingEventTitle}>
                Sampling event {index + 1} <QuestionButtonHelp type='api' slug='project-definition-sampling-event' />
            </div>
            <div>
                <div className={styles.inputLabel}>Sampling event name</div>
                <Input
                    value={samplingEventData.name}
                    onChange={event => onSampleEventChange({ name: event.target.value })}
                    maxLength={25}
                />
            </div>
            <div>
                <div className={styles.expectedDates}>Expected dates</div>
                <div className={styles.dateSelectors}>
                    <div className={styles.dateSelector}>
                        <div className={styles.fromTo}>From</div>
                        <BasicDatePicker
                            value={samplingEventData.fromDate ? new Date(samplingEventData.fromDate) : null}
                            onChange={value => onSampleEventChange({ fromDate: value?.toDateString() })}
                        />
                    </div>
                    <div className={styles.dateSelector}>
                        <div className={styles.fromTo}>To</div>
                        <div>
                            <BasicDatePicker
                                value={samplingEventData.toDate ? new Date(samplingEventData.toDate) : null}
                                onChange={value => onSampleEventChange({ toDate: value?.toDateString() })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SamplingCadence;

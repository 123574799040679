import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import { useAppSelector } from 'src/store';
import { availableAreaColors } from 'src/shared/constants/colors';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import useProject from 'src/shared/hooks/useProject';
import ConditionalField from 'src/shared/components/conditional-field/ConditionalField';
import moment from 'moment';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import { BasicProjectType, ProjectSamplingCadenceType, SubscriptionType } from 'src/shared/types';
import styles from './ProjectDefinition.module.scss';
import ProjectAdminButton from '../components/project-admin-button/ProjectAdminButton';
// TODO: ProjectDefinitionMap import ReviewSamples from '../new-project-definition/components/review-and-submit/components/ReviewSamples';

const ProjectDefinition = () => {
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails) || { customerName: '', customerId: '' };
    const { currentCustomerProjects, currentProjectId } = useProject();
    const currentProjectDetails = currentCustomerProjects?.find(project => project.projectId === currentProjectId);

    if (!currentProjectDetails) {
        return null;
    }

    const {
        projectName,
        projectCode,
        subscription,
        country,
        leadEmail,
        leadName,
        area,
        leadRole,
        projectType,
        targetOutcome,
        samplingCadence,
        sampleGroups,
        speciesOfInterest,
        habitatAssay,
        projectEndDate,
        estimatedSampleCounts,
        estimatedSampleCountForTestTypes,
        // TODO: ProjectDefinitionMap initialSampleEntries,
    } = currentProjectDetails;

    const isBasicAndGenericProject = subscription.name === SubscriptionType.BASIC && projectType === BasicProjectType.GENERIC;

    const mainContent = (
        <div className={styles.reviewAndSubmit}>
            <div className={styles.section}>
                <div className={styles.sectionTitle}>
                    <span>Project details</span>
                </div>
                <div className={styles.sectionItems}>
                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Customer name</div>
                        <div className={styles.value}>{currentCustomerDetails.customerName}</div>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Project name</div>
                        <div className={styles.value}>{projectName}</div>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Project code</div>
                        <div className={styles.value}>{projectCode}</div>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Subscription type</div>
                        <div className={styles.value}>{subscription.label}</div>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Sampling country</div>
                        <div className={styles.value}>{country}</div>
                    </div>
                    <div className={styles.sectionItem}>
                        <div className={styles.label}>
                            Project end date <QuestionButtonHelp type='api' slug='project-definition-end-date' />
                        </div>
                        <ConditionalField showWarning={false} isSatisfied={subscription.name !== SubscriptionType.BASIC}>
                            <div className={styles.value}>{moment(projectEndDate).format(STANDARD_DATE_FORMAT)}</div>
                        </ConditionalField>
                    </div>
                </div>
                <div className={styles.sectionItems}>
                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Area</div>
                        <ConditionalField showWarning={false} isSatisfied={subscription.name !== SubscriptionType.BASIC}>
                            <div className={styles.value}>{area}</div>
                        </ConditionalField>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Customer contact</div>
                        <div className={styles.customerContact}>
                            <div className={styles.userIcon}>
                                <PersonOutlineOutlinedIcon />
                            </div>
                            <div className={styles.contactDetails}>
                                <div className={styles.contactDetailsName}>{leadName}</div>
                                <div>{leadEmail}</div>
                            </div>
                            <div className={styles.role}>
                                <div>{leadRole}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.sectionTitle}>
                    <span>Project aims</span>
                </div>
                <div className={styles.sectionItems}>
                    <div className={styles.sectionItem}>
                        <div className={styles.label}>
                            Project type <QuestionButtonHelp type='api' slug='project-definition-project-type' />
                        </div>
                        <div className={styles.value}>{projectType}</div>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Target outcomes</div>
                        <ConditionalField showWarning={false} isSatisfied={!isBasicAndGenericProject}>
                            <div className={styles.value}>{targetOutcome}</div>
                        </ConditionalField>
                    </div>
                </div>
                <div className={styles.sectionItems}>
                    <div className={styles.sectionItem}>
                        <div className={styles.label}>
                            Sample groups <QuestionButtonHelp type='api' slug='project-definition-sample-groups' />
                        </div>
                        <ConditionalField showWarning={false} isSatisfied={!isBasicAndGenericProject}>
                            <div className={styles.sampleGroups}>
                                {sampleGroups.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.sampleGroupTag}>
                                            <div
                                                className={styles.sampleGroupTagColor}
                                                style={{ backgroundColor: availableAreaColors[index] }}
                                            ></div>
                                            <div className={styles.sampleGroupTagValue}>{entry}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ConditionalField>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>Species of interest</div>
                        <ConditionalField showWarning={false} isSatisfied={!isBasicAndGenericProject}>
                            <div className={styles.sampleGroups}>
                                {speciesOfInterest.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.sampleGroupTag}>
                                            <div className={styles.sampleGroupTagValue}>{entry}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ConditionalField>
                    </div>

                    <div className={styles.sectionItem}>
                        <div className={styles.label}>
                            Test <QuestionButtonHelp type='api' slug='project-definition-habitat-test-type' />
                        </div>
                        <div className={styles.habitatAssayTypes}>
                            {habitatAssay.map((entry, index) => {
                                return (
                                    <div key={index} className={styles.habitatAssayTypeTag}>
                                        <div key={index} className={styles.habitatAssayTypeTagValue}>
                                            <div className={styles.habitatName}>{entry.habitatName}</div>
                                            <div className={styles.assayName}>{entry.assayName}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.sectionTitle}>
                    <span>Sampling cadence</span>
                </div>
                {samplingCadence ? (
                    <>
                        <div className={styles.sectionItems}>
                            <div className={styles.sectionItem}>
                                <div className={styles.label}>Multi year or single year sampling</div>
                                <div className={styles.value}>
                                    {samplingCadence.type === ProjectSamplingCadenceType.SINGLE_YEAR ? 'Single year' : 'Multi year'}
                                </div>
                            </div>

                            <div className={styles.sectionItem}>
                                <div className={styles.label}>
                                    Sampling events per year <QuestionButtonHelp type='api' slug='sampling-events-per-year' />
                                </div>
                                <div className={styles.value}>{samplingCadence.samplingEventsPerYear}</div>
                            </div>
                        </div>
                        <div className={styles.sectionItems}>
                            <div className={styles.samplingEvents}>
                                {samplingCadence.events.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.samplingEvent}>
                                            <div className={styles.eventNumber}>
                                                Sampling event {index + 1}{' '}
                                                <QuestionButtonHelp type='api' slug='project-definition-sampling-event' />
                                            </div>
                                            <div className={styles.eventName}>{entry.name}</div>
                                            <div className={styles.datesTitle}>Expected dates</div>
                                            <div className={styles.dates}>
                                                <span className={styles.dateLabel}>From</span>
                                                <span className={styles.dateValue}>
                                                    {moment(entry.fromDate).format(STANDARD_DATE_FORMAT)}
                                                </span>
                                                <span className={styles.dateLabel}>To</span>
                                                <span className={styles.dateValue}>
                                                    {moment(entry.toDate).format(STANDARD_DATE_FORMAT)}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                ) : (
                    'Not set'
                )}
            </div>
            {subscription.name !== SubscriptionType.INSIGHTS && (
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Samples</span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Total number of samples</div>
                            <div className={styles.value}>
                                <span className={styles.totalSamples}>{estimatedSampleCounts}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Number of samples per test type</div>
                            {estimatedSampleCountForTestTypes ? (
                                <div className={styles.samplesPerTestType}>
                                    {estimatedSampleCountForTestTypes.map((entry, index) => {
                                        return (
                                            <div key={index} className={styles.samplesPerTestTypeItem}>
                                                <div className={styles.count}>{entry.count}</div>
                                                <div className={styles.habitatName}>{entry.type.habitatName}</div>
                                                <div className={styles.assayName}>{entry.type.assayName}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                'Not set'
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* TODO: ProjectDefinitionMap {subscriptionType === SubscriptionType.INSIGHTS && (
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Samples</span>
                    </div>
                    <ReviewSamples sampleGroups={sampleGroups} initialSampleEntries={initialSampleEntries} />
                </div>
            )} */}
        </div>
    );

    return (
        <StandardAppLayout title='Project summary' subTitle={projectName} mainContent={mainContent} otherActions={<ProjectAdminButton />} />
    );
};

export default ProjectDefinition;

import React, { useEffect, useState } from 'react';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import { dataTableDefaultDownloadHandler } from 'src/shared/components/data-table/helpers/downloadHandler';
import DownloadButton from 'src/shared/components/download-button/DownloadButton';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import useProject from 'src/shared/hooks/useProject';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { AppName } from 'src/global/globalConfig';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import styles from './SampleMetricsTable.module.scss';
import { useSampleMetricsTableDataQuery } from '../state/api/tablesGraphSlice';

const PAGE_TITLE = 'Sample metrics table';
export const SampleMetricsTable = () => {
    const {
        currentProjectFilters,
        currentProjectDetails,
        currentProjectId,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
    } = useProject();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const reportDate = moment(currentProjectDetails?.latestReportingDate).format(STANDARD_DATE_FORMAT);
    const [columns, setColumns] = useState<DataTableColumns>([]);
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
    });

    const { currentData, isFetching } = useSampleMetricsTableDataQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                habitatAssay: [habitatAssayType?.key || ''],
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    useEffect(() => {
        if (!currentData || !currentProjectFilters) {
            return;
        }

        const { habitatAssays } = currentProjectFilters;

        const {
            sampleMetricsTableData: { samples },
        } = currentData;

        const metricColumns: DataTableColumns = [];

        const habitatAssay = habitatAssays.find(entry => entry.key === habitatAssayType?.key);

        if (!habitatAssay) {
            return;
        }

        const { metrics, assay } = habitatAssay;

        const { assayId } = assay;

        metrics.forEach(metric => {
            metricColumns.push({
                columnId: `${assayId}-${metric.metricKey}`,
                title: metric.metricName,
            });
        });

        const columnsAndIndex: { [key: string]: number } = {};

        metricColumns.forEach((entry, index) => {
            columnsAndIndex[entry.columnId] = index + 1;
        });
        const totalColumns = metricColumns.length + 4;
        const tableData: DataTableState['data'] = [];

        samples.forEach(sample => {
            const { metrics, sampleId, location, covariates, sampleReceivedDate } = sample;
            const currentRow = Array(totalColumns).fill('');
            currentRow[0] = sampleId;
            metrics.forEach(metric => {
                const columnId = `${metric.assayId}-${metric.metricKey}`;
                currentRow[columnsAndIndex[columnId]] = metric.metricValue;
            });
            currentRow[totalColumns - 3] = moment(sampleReceivedDate).format(STANDARD_DATE_FORMAT);
            currentRow[totalColumns - 2] = covariates[0].covariateValue;
            currentRow[totalColumns - 1] = location.coordinates.join(', ');
            tableData.push(currentRow);
        });
        setColumns([
            {
                columnId: 'sampleId',
                title: 'Sample ID',
                width: '120px',
            },
            ...metricColumns,
            {
                columnId: 'sampleReceivedDate',
                title: 'Sample Received Date',
                width: '120px',
            },
            {
                columnId: 'covariates',
                title: 'Sample Group',
                width: 'auto',
            },
            {
                columnId: 'location',
                title: 'Latitude Longitude',
                width: 'auto',
            },
        ]);

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [currentData, habitatAssayType]);

    const onHabitatAssayTypeChange = (value: string) => {
        // Reset table state
        setTableState({});
        setColumns([]);
        setCurrentProjectHabitatAssayType(value);
    };

    if (isFetching) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    if (!currentProjectDetails || !currentData || !tableState.data) {
        return null;
    }

    const handleDownload = () => {
        if (!tableState.data) {
            return;
        }

        const downloadFileName = [
            'NM-',
            currentProjectId,
            '.',
            upperFirst(habitatAssayType?.habitat.habitatName),
            '.',
            upperFirst(habitatAssayType?.assay.assayName),
            '.Metrics.',
            moment(currentProjectDetails?.latestReportingDate).format('DDMMYY'),
            '.csv',
        ].join('');

        dataTableDefaultDownloadHandler({
            data: tableState.data,
            columns,
            downloadFileName,
        });
    };
    const otherActions = (
        <div className={styles.otherActions}>
            <div className={styles.reportDate}>
                <span className={styles.reportDateLabel}>Report Date:</span>
                <span className={styles.reportDateValue}>{reportDate}</span>
            </div>
            <DownloadButton onClick={handleDownload} className={styles.downloadButton} />
        </div>
    );

    const mainContent = (
        <div className={styles.tableWrapper}>
            <DataTable state={tableState} columns={columns} onStateChange={setTableState} className={styles.dataTable} />
        </div>
    );

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={onHabitatAssayTypeChange}
            app={AppName.SAMPLE_METRICS_TABLE}
        />
    );

    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={mainContent}
            otherActions={otherActions}
            title={PAGE_TITLE}
            subTitle={<CMSArticle slug='sample-metrics-table-description' />}
            breadcrumbs={currentProjectBreadcrumbDetails}
            page={AppName.SAMPLE_METRICS_TABLE}
        />
    );
};

export default SampleMetricsTable;

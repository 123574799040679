import React, { useEffect, useState } from 'react';
import moment from 'moment';
import uniq from 'lodash/uniq';
import InputLabel from '@mui/material/InputLabel';
import useProject from 'src/shared/hooks/useProject';
import useIsViewed from 'src/shared/hooks/useIsViewed';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import { getQuarterDateRange, getDateQuarterSelectOption } from 'src/shared/utils/dateHelpers';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useRefWithRerender from 'src/shared/hooks/useRefWithRerender';
import useFeatures from 'src/shared/hooks/useFeatures';

import { useAppDispatch, useAppSelector } from 'src/store';
import { useSpeciesMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { updateIucnMetricUiState } from 'src/app/dashboard/state/dashboardGeneralSlice';

import IucnTable from 'src/app/dashboard/components/metrics/metrics-table/components/IucnTable';
import {
    filterByRedListStatus,
    filterByGRIISList,
    filterByCHEGDStatus,
} from 'src/app/dashboard/components/metrics/metrics-table/metricsTableHelpers';
import useDashboardAnalytics from 'src/app/dashboard/hooks/useDashboardAnalytics';

import styles from './MetricsTable.module.scss';
import ChegdTable from './components/ChegdTable';

const MetricsTable = () => {
    const {
        currentProjectId,
        currentProjectDetails,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        isSingleTimepoint,
    } = useProject();
    const [timepointsOptions, setTimesPointOptions] = useState<SelectOption[]>([]);
    const uiState = useAppSelector(getCurrentDashboardUIState);
    const dispatch = useAppDispatch();
    const [ref, callbackRef] = useRefWithRerender<HTMLDivElement>(null);
    const isViewed = useIsViewed(ref);
    const { trackIUCNTableViewed } = useDashboardAnalytics();
    const { hasIucnRedListFeature, hasInvasivesFeature, hasChegdFeature } = useFeatures();

    const hideDateFilter = isSingleTimepoint;

    const {
        iucnMetric: { selectedTimePoint },
    } = uiState;

    const { quarterStart, quarterEnd } = getQuarterDateRange(selectedTimePoint) || {};

    const { currentData, isFetching } = useSpeciesMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: quarterStart,
                toDate: quarterEnd,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    useEffect(() => {
        if (isViewed) {
            trackIUCNTableViewed();
        }
    }, [isViewed]);

    useEffect(() => {
        if (!currentProjectDetails || !currentProjectDetails.samples.length) {
            return;
        }

        const samples = currentProjectDetails.samples;
        const uniqueSampleDates = uniq(samples.map(entry => moment(entry.sampleCollectedDatetime).startOf('Q').valueOf())).sort();

        const newTimepointOptions = uniqueSampleDates.map(entry => getDateQuarterSelectOption(entry)).reverse();
        setTimesPointOptions(newTimepointOptions);
        if (!selectedTimePoint && newTimepointOptions.length > 0) {
            onTimePointChange(newTimepointOptions[0].value);
        }
    }, [currentProjectDetails]);

    const onTimePointChange = (timePointValue: string) => {
        dispatch(updateIucnMetricUiState({ selectedTimePoint: timePointValue }));
    };

    if (!currentData || isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { speciesMetrics: speciesMetricsData } = currentData;
    const { taxonomySummary } = speciesMetricsData;

    const iucnRedLists = filterByRedListStatus(taxonomySummary);
    const invasiveLists = filterByGRIISList(taxonomySummary);
    const chegdSpeciesList = filterByCHEGDStatus(taxonomySummary);

    const header = (
        <div>
            <div className={styles.filter} ref={callbackRef}>
                <div className={styles.title}>Results in this table are only displayed for one quarter</div>
                <div>|</div>
                <div className={styles.filter}>
                    <InputLabel className={styles.filterLabel}>Display detections in</InputLabel>
                    <div className={styles.filterSelect}>
                        <Select
                            width={175}
                            options={timepointsOptions}
                            onChange={value => onTimePointChange(value)}
                            selectedValue={selectedTimePoint || timepointsOptions[0]?.value}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.iucnMetrics}>
            {!hideDateFilter && header}
            <div>
                {hasIucnRedListFeature && (
                    <IucnTable data={[...iucnRedLists]} title='IUCN Red List species' type='redLists' helpArticleSlug='iucn-status' />
                )}
                {hasChegdFeature && <ChegdTable data={chegdSpeciesList} />}
                {!hasChegdFeature && hasInvasivesFeature && (
                    <IucnTable data={[...invasiveLists]} title='Invasive species' type='invasiveLists' helpArticleSlug='invasive-species' />
                )}
            </div>
        </div>
    );
};

export default MetricsTable;
